$("#link-to-contact").click(function() {
    $('html, body').animate({
        scrollTop: $("#contact").offset().top
    }, 2000);
});

function open_mobile_nav() {
	$(".header-bottom .collapse.navbar-collapse").removeClass("collapse");
}

function close_mobile_nav() {
	$(".header-bottom .navbar-collapse").addClass("collapse");
}

// Popup watch video.
window.document.onkeydown = function (e) {
	if (!e) {
		e = event;
	}
	if (e.keyCode == 27) {
		lightbox_close();
	}
};

function lightbox_open() {
	window.scrollTo(0, 0);
	document.getElementById("light").style.display = "block";
	document.getElementById("fade").style.display = "block";
	const src = $("#light").find("iframe").attr("src");
	$("#light").find("iframe").attr("src", src + "&autoplay=1");
	$('body').css('overflow','hidden');
}

function lightbox_close() {
	document.getElementById("light").style.display = "none";
	document.getElementById("fade").style.display = "none";
	$('body').css('overflow','auto');
}
$(document).ready(function() {
	window.open_mobile_nav = open_mobile_nav;
	window.close_mobile_nav = close_mobile_nav;
	window.lightbox_open = lightbox_open;
	window.lightbox_close = lightbox_close;
})
